<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-1">
          <div
            class="d-flex justify-content-between align-items-center px-1 py-50"
          >
            <div>
              <h3 class="m-0 py-1">{{ $t("Productos") }}</h3>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <b-link :to="{ name: 'createProduct' }">
                  <b-button
                      variant="primary"
                      size="sm"
                      class="mr-1 text-nowrap"
                      v-if="role === 'admin_cliente'"
                  >
                    {{ $t("Añadir Producto") }}
                  </b-button>
                </b-link>
              </div>
              <span
                  class="ml-1 cursor-pointer"
                  @click="visibleFilter = !visibleFilter"
              >
            <feather-icon icon="FilterIcon" size="20" />
          </span>
            </div>
          </div>

          <div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="4" v-if="role === 'admin_cliente'">
                    <b-form-checkbox
                        id="accountSwitch1"
                        v-model="checkAllProducts"
                        name="check-button"
                        inline
                    >
                      <span>{{ $t('Mostrar todos los productos') }}</span>
                    </b-form-checkbox>

                  </b-col>
                  <b-col sm="4" v-if="role === 'admin_empresa' && !currentClient">
                    <b-form-group
                        :label="$t('Cliente')"
                        label-for="filter-client"
                    >
                      <v-select
                          v-model="client"
                          label="name"
                          :filterable="true"
                          :searchable="true"
                          :options="listClients"
                          :placeholder="$t('Cliente')"
                      >
                        <template slot="option" slot-scope="option">
                          {{ option.name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                        :label="$t('Busqueda')"
                        label-for="filter-search"
                    >
                      <b-row>
                        <b-col sm="8">
                          <b-form-input
                              v-model="searchTerm"
                              :placeholder="$t('Busqueda')"
                              type="text"
                              class="d-inline-block"
                          />
                        </b-col>
                        <b-col sm="4" style="padding-left: 0px">
                          <b-button @click="handleSearch" variant="primary">
                            {{ $t("Buscar") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table hover responsive :items="items" :fields="seleccionarcolumnas">
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div
                  :style="`background-image: url('${data.item.imagen}');`"
                  class="d-inline-block img-product-list mr-50"
                ></div>
                <p class="d-inline-block m-0">{{ data.item.name }}</p>
              </div>
            </template>
            <template #cell(client)="data">
              <span v-if="data.item.client">
                {{ data.item.client.name }}
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewProduct', params: { id: data.item.id } }"
                  class="mr-1"


                >
                  <feather-icon icon="EyeIcon" size="16" />
                </b-link>
                <b-link
                    v-if="canEdit(data.item.client_id)"
                    :to="{ name: 'editProduct', params: { id: data.item.id } }"
                    class="mr-1"
                >
              <feather-icon icon="Edit2Icon" size="16" />
            </b-link>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span
            >
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BLink,
  BInputGroup,
  BInputGroupPrepend,
    BFormGroup,
    BCollapse,
    BFormCheckbox,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import vSelect from "vue-select";
import router from "@/router";

export default {
  components: {
    BBadge,
    BAvatar,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BCollapse,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      log: [],
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      checkAllProducts: false,
      client: {},
      pages: ["10", "15", "25"],
      columns: [
        {
          label: this.$t("Nombre"),
          key: "name",
        },
        {
          label: this.$t("NActivos"),
          key: "num",
        },
        {
          label: this.$t("Cliente"),
          key: "client",
        },
        {
          label: this.$t("Modelo"),
          key: "modelo",
        },
        {
          label: this.$t("codigoArticulo"),
          key: "codart",
        },
        {
          label: "EAN13",
          key: "ean13",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
        },
      ],
      columnsClient: [
        {
          label: this.$t("Nombre"),
          key: "name",
        },
        {
          label: this.$t("NActivos"),
          key: "num",
        },
        {
          label: this.$t("Modelo"),
          key: "modelo",
        },
        {
          label: this.$t("codigoArticulo"),
          key: "codart",
        },
        {
          label: "EAN13",
          key: "ean13",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      items: "products/getItems",
      totalItems: "products/getTotalItems",
      currentClient: "clients/getCurrentClient",
      listClients: "clients/getItems",
      role: "auth/getRole",
      user: "auth/getUser",
    }),
    seleccionarcolumnas() {
      if (this.role === 'admin_cliente') {
        return this.columnsClient
      }
      return this.columns
    },
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-danger",
      };

      return (status) => statusColor[status];
    },
    statusVariantText() {
      const statusText = {
        1: "Activo",
        0: "Desactivado",
      };

      return (status) => statusText[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  watch: {
    client() {
      this.chargeData();
    },
    checkAllProducts() {
      this.chargeData();
    }
  },
  created() {
    this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: "",
    });
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "products/getListProducts",
      getListClients: "clients/getListClients",
    }),
    canEdit(client_id) {
      let ifedit = false
      if((this.role === 'admin_cliente' && client_id === this.user.clients[0].id) ||
          (this.role === 'admin_empresa' && client_id === null) ||
          this.role === 'super_admin'){
        ifedit = true
      }
      return ifedit
    },
    chargeData() {

      let cliente = this.currentClient ? this.currentClient.id : ""
      if(this.role !== 'admin_cliente' && cliente === ""){
        cliente =  this.client ? this.client.id : ''
      }
      if(this.role === 'admin_cliente' && this.checkAllProducts ){
        cliente = ''
      }

      this.list({
        company: this.currentCompany.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        client: cliente
      });


    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
